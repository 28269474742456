import { NgModule } from '@angular/core';
import { LocaleModule } from '@x/common/locale';
import { AuthSharedModule } from '@x/ecommerce-admin/app/auth/auth-shared.module';
import { AgreementSharedModule } from '../agreement/agreement-shared.module';
import { EcommerceAdminCoreModule } from '../core/ecommerce-admin-core.module';
import { MembershipsSharedModule } from '../memberships/memberships-shared.module';
import { OrdersSharedModule } from '../orders/orders-shared.module';
import { PaymentsSharedModule } from '../payments/payments-shared.module';
import { ProductsSharedModule } from '../products/products-shared.module';
import { VoucherSharedModule } from '../voucher/voucher-shared.module';
import { USER_WALLET_INDEX_LANGUAGE } from '../wallets/components/wallet-table/user-wallet-index.language';
import { WalletsSharedModule } from '../wallets/wallets-shared.module';
import { UserCreateDialogComponent } from './components/user-create-dialog/user-create-dialog.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { UserFormComponent } from './components/user-form/user-form.component';
import { UserPaymentMethodTableComponent } from './components/user-payment-method-table/user-payment-method-table.component';
import { UserRoleAssignmentDialogComponent } from './components/user-role-assignment-dialog/user-role-assignment-dialog.component';
import { UserSelectAddressDialogComponent } from './components/user-select-address-dialog/user-select-address-dialog.component';
import { UserTableComponent } from './components/user-table/user-table.component';
import { UserDialogService } from './services/user-dialog.service';
import { USER_LANGUAGE } from './user.language';

@NgModule({
  declarations: [
    UserCreateDialogComponent,
    UserDetailComponent,
    UserFormComponent,
    UserSelectAddressDialogComponent,
    UserRoleAssignmentDialogComponent,
    UserPaymentMethodTableComponent,
    UserTableComponent,
  ],
  providers: [UserDialogService],
  imports: [
    EcommerceAdminCoreModule,
    LocaleModule.forChild([USER_WALLET_INDEX_LANGUAGE, USER_LANGUAGE]),
    ProductsSharedModule,
    OrdersSharedModule,
    WalletsSharedModule,
    AgreementSharedModule,
    MembershipsSharedModule,
    VoucherSharedModule,
    PaymentsSharedModule,
    AuthSharedModule,
  ],
  exports: [UserTableComponent],
})
export class UsersSharedModule {}
